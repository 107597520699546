export const PAGE_TITLES = {
  AUTHENTICATION: 'Authentication',
  ACCOUNT_ACCESS_TOKENS: 'Account Access Tokens',
  BILLING_INFO: 'Billing Info',
  CONNECT: 'Connect',
  CONNECTED_ACCOUNTS: 'Connected Accounts',
  DATA_PROCESSING: 'Data Processing Addendum',
  DATA_RETENTION: 'Data Retention',
  DEFAULT: 'Rollbar',
  EMAIL_SETTINGS: 'Email Settings',
  FIX: 'Fix',
  GENERAL: 'General',
  IDENTITY_PROVIDER: 'Identity Provider',
  IMPROVE: 'Improve',
  INVOICE: 'Invoice',
  INVOICES: 'Invoices',
  MANAGE_USER: 'Manage User',
  PEOPLE: 'Affected People',
  PLAN: 'Your Plan',
  PROJECTS: 'Projects',
  PROJECTS_ACCESS_TOKENS: 'Project Access Tokens',
  RQL: 'RQL',
  SAML: 'SAML Identity Provider',
  SUBSCRIPTION: 'Subscription',
  TEAMS: 'Teams',
  THIRD_PARTY_DATA_ACCESSS: '3rd party Data Access',
  USAGE: 'Usage',
  USERS: 'Users',
  USER_STATUS: 'User Status',
  USER_MANAGEMENT: 'Manage User',
  COMPARE: 'Compare Plans',
};

export const LOGIN_PATH = 'login';
export const AUTHENTICATION_COOKIE_NAMES = ['auth_tkt', 'sAccessToken'];
export const NEW_AUTH_OPT_OUT_COOKIE = 'rollbar_opt_out_st_auth';

export const CONTACT_US_URL = 'https://rollbar.com/contact';
export const DOCS_URL = 'https://docs.rollbar.com/docs';
export const PRIVACY_POLICY_URL = `${DOCS_URL}/privacy-policy`;
export const REFERENCE_URL = 'https://docs.rollbar.com/reference';
export const TERMS_OF_SERVICE_URL = `${DOCS_URL}/terms-of-service`;

export enum ReCaptchaAction {
  LOGIN = 'login',
  SIGNUP = 'signup',
  REQUEST_PASSWORD_RESET = 'request_password_reset',
  PASSWORD_RESET = 'password_reset',
  TOTP_ENTER_CODE = 'totp_enter_code',
  TOTP_RECOVER = 'totp_recover',
}

export const RECAPTCHA_HEADER_NAME = 'X-ReCaptcha-Token';
