import type { FC, ReactNode } from 'react';

import useUpsell from '@/hooks/useUpsell';
import { UpsellContext } from '@/hooks/useUpsell/types';

type Props = {
  children: ReactNode;
  value: UpsellContext;
};

const UpsellProvider: FC<Props> = ({ children, value }) => {
  const { context } = useUpsell();

  return <context.Provider value={value}>{children}</context.Provider>;
};

UpsellProvider.displayName = 'UpsellProvider';

export { UpsellProvider };
