import { Menu, MenuButton } from '@headlessui/react';
import classnames from 'classnames';
import { createContext, useContext, useState } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import UserMenu from '@/layouts/AppShell/UserMenu';
import Avatar from '@/rollbar-ui/Avatar';

import SidebarHeader from './Header';
import PrimaryNav from './PrimaryNav';
import SecondaryNav from './SecondaryNav';

export const DesktopSidebarContext = createContext({
  isCollapsed: false,
});

function DesktopUserMenu() {
  const { user, accountSlug } = useAccountInfo();
  const { isCollapsed } = useContext(DesktopSidebarContext);

  return (
    <Menu
      as="div"
      id="nav-account-profile"
      className="w-full relative cursor-pointer"
    >
      <MenuButton
        data-testid="desktop-user-menu"
        aria-hidden="true"
        className={classnames(
          'flex flex-shrink-0 w-full px-1.5 group items-center focus:outline-none group-hover:text-white group-hover:no-underline',
          isCollapsed ? 'flex-col' : 'flex-col xl:flex-row'
        )}
      >
        <Avatar
          className={classnames({ 'xl:mr-3 items-center': !isCollapsed })}
          email={user.email}
          size="sm"
        />
        <div
          className={classnames(
            isCollapsed ? 'sr-only' : 'sr-only xl:not-sr-only'
          )}
        >
          <p className="text-sm text-white left-0 w-px">{user.name}</p>
          <p className="text-xs text-white">Manage Settings</p>
        </div>
      </MenuButton>
      <div className="absolute left-full bottom-0 flex z-50">
        <div className="relative w-2.5" />
        <UserMenu user={user} accountSlug={accountSlug} />
      </div>
    </Menu>
  );
}

export default function DesktopSidebar({ className }: { className?: string }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <DesktopSidebarContext.Provider
      value={{
        isCollapsed,
      }}
    >
      <aside
        className={classnames(
          isCollapsed ? 'w-18' : 'w-18 xl:w-40',
          'group transition-width duration-300 ease-linear flex h-screen bg-gray-700 xl:flex-shrink-0 flex-col',
          className
        )}
      >
        <div className="flex-1 flex flex-col pb-4">
          <div className="relative h-13 text-center">
            <SidebarHeader onClick={() => setIsCollapsed(!isCollapsed)} />
            <button
              className={classnames('hidden xl:block absolute top-6 right-2', {
                'opacity-0 group-hover:opacity-100': !isCollapsed,
              })}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <svg
                width="16"
                height="25"
                viewBox="0 0 16 25"
                xmlns="http://www.w3.org/2000/svg"
                className={classnames(
                  'w-1 h-2 transition-transform duration-300 ease-linear',
                  { 'transform rotate-180': isCollapsed }
                )}
              >
                <title>hide-arrow</title>
                <g id="Page-1" fill="#fff" fillRule="evenodd">
                  <g
                    id="Desktop-HD"
                    transform="translate(-214 -228)"
                    fillRule="nonzero"
                  >
                    <g id="hide-arrow" transform="translate(214 228)">
                      <path
                        d="M9.74 19.258l9.723-10.77C20.701 7.12 19.665 5 17.721 5H-1.725c-1.92 0-2.98 2.097-1.743 3.488l9.723 10.77c.91.99 2.576.99 3.485 0z"
                        id="Path"
                        transform="rotate(90 8 12.5)"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>

              <span className="sr-only">
                {isCollapsed ? 'Expand' : 'Collapse'}
              </span>
            </button>
          </div>

          <nav className="flex-1">
            <PrimaryNav />
          </nav>
        </div>
        <div className="flex-shrink-0 flex flex-col py-4">
          <SecondaryNav />
          <DesktopUserMenu />
        </div>
      </aside>
    </DesktopSidebarContext.Provider>
  );
}
