import qs from 'qs';

const { NEXT_PUBLIC_ROLLBAR_BASE_URL } = process.env;

export const loginUrl = (nextUrl?: string) =>
  nextUrl ? `/login?${qs.stringify({ next_url: nextUrl })}` : '/login';

export const logoutUrl = (nextUrl?: string) => {
  const query = nextUrl ? `?${qs.stringify({ next_url: nextUrl })}` : '';
  return `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/logout${query}`;
};

export const SIGNUP_URL = '/signup';

export const moxIndexUrl = () => `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/`;

export const googleLoginCallbackUrl = () =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/callback/google_oauth_login/`;

export const githubLoginCallbackUrl = () =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/callback/github_oauth_login/`;

export const securitySettingsUrl = (account: string) =>
  `/a/${account}/settings/security`;

// saml settings
export const enterpriseSSOLoginUrl = (account: string) =>
  `/auth/saml/${account}`;

export const addIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp/add`;

export const viewIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp`;

export const editIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp/edit`;

export const userAuthenticationSettingsUrl = () => `/settings/authentication`;
export const userAuthenticationRecoveryUrl = () =>
  `${userAuthenticationSettingsUrl()}/recovery`;
// totp

export const TOTP_URL_BASE = '/auth/totp';
export const totpEnterUrl = (nextUrl: string) =>
  `${TOTP_URL_BASE}?${qs.stringify({ next_url: nextUrl })}`;

export const totpRecoverUrl = (nextUrl?: string) =>
  `${TOTP_URL_BASE}/recover?${qs.stringify({ next_url: nextUrl })}`;

export const acsUrl = (account: string, provider: string) =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/${account}/saml/sso/${provider}/`;

// use the mox url, so we can release this updated page even if the
// users are still on the mox auth system
export const samlLoginUrl = (account: string, provider: string) =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/${account}/saml/login/${provider}/`;
