import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

type OnConfirmFunctionParameterType = { onClose: () => void };

export type ToastProps = {
  children?: ReactNode;
  variant: 'success' | 'warning' | 'error' | 'neutral';
  icon?: ReactNode;
  onClose?: () => void;
  confirmAction?: ReactNode;
  cancelAction?: ReactNode;
  onConfirm?: ({ onClose }: OnConfirmFunctionParameterType) => void;
  onCancel?: () => void;
};

const backgroundColorMapper = {
  success: 'bg-green-stripe',
  warning: 'bg-amber-stripe',
  error: 'bg-orange-stripe',
  neutral: 'bg-blue-stripe',
};

const toastIcon = {
  success: CheckCircleIcon,
  warning: ExclamationCircleIcon,
  error: XCircleIcon,
  neutral: InformationCircleIcon,
};

export default function Toast({
  variant,
  children,
  onClose,
  confirmAction,
  cancelAction,
  onConfirm,
  onCancel,
}: ToastProps) {
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (!onConfirm) {
      setTimeout(() => {
        setShow(false);
      }, 6000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    handleClose();
  };

  const Icon = toastIcon[variant];

  return (
    <Transition
      appear={true}
      show={show}
      enter="transition-opacity duration-700"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={onClose}
    >
      <div
        className={classNames(
          'rounded-md shadow-md mb-1 w-72 font-normal text-sm text-gray-700',
          backgroundColorMapper[variant]
        )}
      >
        <div className="flex">
          <div className="pl-4 pt-4 flex-shrink-0">
            <Icon className="h-5 w-5" />
          </div>
          <div className="flex flex-col flex-1 items-start pl-3 pr-4 py-4">
            {children}

            <div className="flex flex-row">
              {confirmAction && onConfirm && (
                <button
                  className="mt-3 mr-3 text-gray-700 underline"
                  onClick={() => {
                    onConfirm({ onClose: handleClose });
                  }}
                >
                  {confirmAction}
                </button>
              )}
              <button
                className="mt-3 text-gray-700 underline"
                onClick={handleCancel}
              >
                {cancelAction || 'Close'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
