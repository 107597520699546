import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import classnames from 'classnames';
import { Fragment, ReactNode, useState } from 'react';

export type NotificationVariant = 'success' | 'warning' | 'danger' | 'error';

export type SimpleProps = {
  variant: NotificationVariant;
  children: ReactNode;
  onClose?: () => void;
};

const redXCircleIcon = (
  <XCircleIcon className="h-6 w-6 text-red-500 bg-red-50" aria-hidden="true" />
);

const iconMapping = {
  success: (
    <CheckCircleIcon
      className="h-6 w-6 text-green-500 bg-green-50"
      aria-hidden="true"
    />
  ),
  warning: (
    <ExclamationTriangleIcon
      className="h-6 w-6 text-amber-500 bg-amber-50"
      aria-hidden="true"
    />
  ),
  danger: redXCircleIcon,
  error: redXCircleIcon,
};

export default function Simple({
  variant,
  children,
  onClose = () => {},
}: SimpleProps) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const icon = iconMapping[variant];

  const containerClassName = classnames(
    'max-w-md w-full shadow-lg rounded-lg pointer-events-auto overflow-hidden',
    {
      'bg-green-50': variant === 'success',
      'bg-amber-50': variant === 'warning',
      'bg-red-50': variant === 'danger' || variant === 'error',
    }
  );

  const xClassName = classnames(
    'rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2',
    {
      'bg-green-50': variant === 'success',
      'bg-amber-50': variant === 'warning',
      'bg-red-50': variant === 'danger' || variant === 'error',
    }
  );

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-1 pointer-events-none sm:items-start z-toast"
      >
        <div className="w-full flex flex-col items-center space-y-4">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={containerClassName}>
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">{icon}</div>
                  <div className="ml-3 w-0 flex-1 text-sm leading-5">
                    {children}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className={xClassName}
                      onClick={handleClose}
                      data-testid="notification-closer"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
