import { ClientError } from 'graphql-request';
import Router from 'next/router';

import { LOGIN_PATH } from './constants';

/**
 * Since the gql client doesn't return a 401, this function checks to see if
 * any of the errors are "unauthorized" or "unauthenticated".
 * If any of them are, it will redirect to the login url, much like the
 * axios interceptor in api/client.ts does.
 *
 * TODO: Add this to graphql-request middleware (when we decide on how to implement middleware)
 * @param error
 */
export default function redirectUnauthorizedGraphqlToLogin(error: unknown) {
  if (typeof window !== 'undefined' && error instanceof ClientError) {
    const unauthorizedErrors = error.response.errors?.filter(
      (e) => e.message === 'unauthorized' || e.message === 'unauthenticated'
    );
    if (unauthorizedErrors && unauthorizedErrors.length) {
      Router.push(
        `${process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL}/${LOGIN_PATH}?next_url=${window.location.href}`
      );
    }
  }
}
