import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import { MouseEventHandler, ReactNode } from 'react';

type InternalLinkProps = Pick<LinkProps, 'href'> & {
  children: ReactNode;
  className?: string;
  mode?: 'dark' | 'light';
  onClick?: MouseEventHandler;
  title?: string;
};

export function InternalLink({
  children,
  className,
  href,
  mode = 'light',
  onClick,
  title,
  ...props
}: InternalLinkProps) {
  return (
    <Link
      {...props}
      className={classNames('hover:underline', className, {
        'text-blue-700 hover:text-blue-500': mode === 'light',
        'text-blue-300 hover:text-blue-400': mode === 'dark',
      })}
      href={href}
      onClick={onClick}
      title={title}
    >
      {children}
    </Link>
  );
}
