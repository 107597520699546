'use client';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { AccountAnalytics } from '@/components/compat/analytics/AccountAnalytics';
import { UserAnalytics } from '@/components/compat/analytics/UserAnalytics';
import { analytics } from '@/lib/analytics/analytics';

export function Analytics() {
  const router = useRouter();

  const route = router.route;
  const logPage = useCallback(() => {
    analytics.page({
      route,
    });
  }, [route]);

  useEffect(() => {
    logPage();

    router.events.on('routeChangeComplete', logPage);

    return () => {
      router.events.off('routeChangeComplete', logPage);
    };
  }, [router.events, logPage]);

  return (
    <>
      <UserAnalytics />
      <AccountAnalytics />
    </>
  );
}
