import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import {
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useContext } from 'react';

import { DesktopSidebarContext } from './Desktop';

export default function HelpMenu() {
  const { isCollapsed } = useContext(DesktopSidebarContext);
  const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;

  return (
    <Menu as="div" className="w-full relative cursor-pointer">
      <MenuButton
        className={classNames(
          'flex flex-shrink-0 w-full group items-center group-hover:text-white group-hover:no-underline',
          isCollapsed ? 'flex-col' : 'flex-col xl:flex-row'
        )}
      >
        <div
          className={classNames(
            'text-gray-300 fill-current',
            'hover:text-white hover:no-underline',
            'relative group flex items-center h-13 leading-4',
            isCollapsed
              ? 'flex-col justify-center'
              : 'flex-col justify-center p-3 xl:flex-row xl:justify-start'
          )}
        >
          <QuestionMarkCircleIcon
            className={classNames('flex-shrink-0 h-6 w-6', {
              'xl:mr-3': !isCollapsed,
            })}
          />
          <span
            className={classNames(
              'text-sidebar-nav font-normal tracking-sidebar-nav',
              isCollapsed ? 'sr-only' : 'sr-only xl:not-sr-only'
            )}
          >
            Help
          </span>
        </div>
      </MenuButton>
      <div className="absolute left-full bottom-0 flex z-50">
        <div className="relative w-2.5" />
        <MenuItems>
          <div className="h-2.5 w-2.5 absolute bg-white left-1 bottom-4 rotate-45 transform" />
          <div className="bg-white shadow-md rounded-sm">
            <div className="flex flex-col pb-2 relative w-32 text-black cursor-pointer text-sm">
              <MenuItem>
                {({ focus }) => (
                  <a
                    href={`${baseUrl}/docs`}
                    className={classNames(
                      'pt-3 pr-4 pb-1.5 pl-4 flex flex-row items-center no-underline cursor-pointer border-b-0',
                      focus ? 'text-blue-600' : 'text-black hover:text-blue-600'
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DocumentTextIcon className="h-6 w-6 mr-2" />
                    <span className="w-full whitespace-nowrap text-ellipsis block overflow-hidden">
                      Docs
                    </span>
                  </a>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <a
                    href={`${baseUrl}/support`}
                    className={classNames(
                      'pt-3 pr-4 pb-1.5 pl-4 flex flex-row items-center no-underline cursor-pointer border-b-0',
                      focus ? 'text-blue-600' : 'text-black hover:text-blue-600'
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ChatBubbleLeftRightIcon className="h-6 w-6 mr-2" />
                    <span className="w-full whitespace-nowrap text-ellipsis block overflow-hidden">
                      Support
                    </span>
                  </a>
                )}
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </div>
    </Menu>
  );
}
