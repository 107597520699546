import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import Avatar from '@/rollbar-ui/Avatar';
import Button from '@/rollbar-ui/Button';
import SlideOver from '@/rollbar-ui/SlideOver';

import {
  defaultShouldHighlightNavItem,
  usePrimaryNavItems,
  useSecondaryNavItems,
} from './helpers';
import { NavLinkProps } from './NavLink';

const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;

export default function MobileNav() {
  const { user } = useAccountInfo();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        variant="secondary"
        size="square"
        onClick={() => setIsOpen(!isOpen)}
        className="mr-2 md:hidden"
      >
        {!isOpen && <span className="sr-only">Open Menu</span>}
        <Bars3Icon className="h-4 w-4" />
      </Button>
      <SlideOver
        shouldOpenFromLeft={true}
        hasCloseButton={false}
        hasTopPadding={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className="h-full flex flex-col bg-gray-50">
          <header className="flex flex-row items-center justify-between px-6 py-3 border-b-2 border-gray-200 bg-white">
            <img
              className="h-6 w-6"
              src="/images/common/rollbar-icon-gray.svg"
              alt=""
              aria-hidden="true"
            />
            <Button
              variant="secondary"
              size="square"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Close Menu</span>
              <XMarkIcon className="h-4 w-4" />
            </Button>
          </header>
          <nav className="overflow-y-auto">
            {usePrimaryNavItems().map(({ id, name, href, icon: Icon }) => {
              return (
                <MobileNavLink
                  key={id}
                  id={id}
                  name={name}
                  href={href}
                  icon={<Icon className="h-6 w-6" aria-hidden="true" />}
                />
              );
            })}
            {useSecondaryNavItems().map(({ id, name, href, icon: Icon }) => {
              return (
                <MobileNavLink
                  key={id}
                  id={id}
                  name={name}
                  href={href}
                  icon={<Icon className="h-6 w-6" aria-hidden="true" />}
                />
              );
            })}
            <MobileNavLink
              id="nav-support"
              name="Support"
              href={`${baseUrl}/support`}
              icon={
                <QuestionMarkCircleIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              }
            />
            <MobileNavLink
              id="nav-profile"
              name="Profile"
              href={`${baseUrl}/settings/profile/`}
              icon={<Avatar email={user.email} size="xs" />}
            />
          </nav>
        </div>
      </SlideOver>
    </>
  );
}

function MobileNavLink({ id, name, href, icon: Icon }: NavLinkProps) {
  const { asPath } = useRouter();
  const isHighlighted = defaultShouldHighlightNavItem(asPath, href);
  return (
    <Link href={href} id={id}>
      <div
        className={classNames(
          'flex flex-row px-6 py-3 items-center fill-current',
          isHighlighted ? 'bg-blue-400 text-white' : 'text-gray-500'
        )}
      >
        {Icon}
        <span
          className={classNames(
            'ml-4 font-normal text-sm',
            !isHighlighted && 'text-gray-700'
          )}
        >
          {name}
        </span>
      </div>
    </Link>
  );
}
