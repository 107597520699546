'use client';
import classNames from 'classnames';
import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';
import { forwardRef } from 'react';

import { Spinner } from '@/rollbar-ui/Waiting/Spinner';

const variants = [
  'primary',
  'secondary',
  'construct',
  'destruct',
  'link',
  'ghost',
  'inverted-primary',
  'inverted-construct',
  'inverted-destruct',
] as const;

const sizes = ['xs', 'sm', 'md', 'lg', 'square', 'fat'] as const;

export type ButtonProps<T extends ElementType> = {
  rounded?: boolean;
  variant?: typeof variants[number];
} & BaseButtonProps<T>;

type BaseButtonProps<T extends ElementType> = {
  as?: ElementType<any>;
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  size?: typeof sizes[number];
} & ComponentPropsWithoutRef<T>;

export default forwardRef(function Button<T extends ElementType>(
  {
    as: Component = 'button',
    children,
    className,
    isLoading = false,
    loadingText,
    rounded,
    size = 'md',
    variant = 'primary',
    ...buttonProps
  }: BaseButtonProps<T>,
  ref: any
) {
  const buttonDefaults = Component === 'button' ? { type: 'button' } : null;
  const { disabled } = buttonProps;

  return (
    <Component
      ref={ref}
      {...buttonDefaults}
      {...buttonProps}
      className={classNames(
        'inline-flex items-center justify-center box-border rounded transition duration-200 button-ease',
        {
          'px-3 py-1 h-7':
            (size === 'xs' || size === 'sm') && variant !== 'link',
          'px-3 py-2 h-8':
            (size === 'md' || size === 'lg') && variant !== 'link',
          'px-2 py-2 h-8': size === 'square' && variant !== 'link',
          'px-2 py-4 h-8': size === 'fat' && variant !== 'link',
          'px-2 py-1': variant === 'link',
          'text-white border-2 bg-blue-700 border-blue-700 hover:bg-blue-500 hover:border-blue-500 active:border-blue-800 active:bg-blue-800':
            variant === 'primary' && !disabled,
          'text-white border-2 bg-blue-700 border-blue-700 opacity-30 cursor-not-allowed':
            variant === 'primary' && disabled,
          'text-gray-500 border-[1.5px] bg-white border-gray-200 hover:bg-gray-50 hover:border-gray-300 active:border-gray-200 active:bg-gray-100':
            variant === 'secondary' && !disabled,
          'text-gray-500 border-[1.5px] bg-white border-gray-200 opacity-30 cursor-not-allowed':
            variant === 'secondary' && disabled,
          'text-gray-700 border-2 bg-green-500 border-green-500 hover:bg-green-400 hover:border-green-400 active:border-green-600 active:bg-green-600':
            variant === 'construct' && !disabled,
          'text-gray-700 border-2 bg-green-500 border-green-500 opacity-30 cursor-not-allowed':
            variant === 'construct' && disabled,
          'text-white border-2 bg-red-700 border-red-700 hover:bg-red-500 hover:border-red-500 active:border-red-800 active:bg-red-800':
            variant === 'destruct' && !disabled,
          'text-white border-2 bg-red-700 border-red-700 opacity-30 cursor-not-allowed':
            variant === 'destruct' && disabled,
          'text-blue-700 hover:text-blue-800 focus:text-blue-800 focus:ring-1 ring-blue-800':
            variant === 'link' && !disabled,
          'text-gray-700 border-2 border-transparent bg-white hover:bg-gray-200 active:bg-gray-300':
            variant === 'ghost' && !disabled,
          'text-gray-700 border-2 border-transparent bg-white opacity-30 cursor-not-allowed':
            variant === 'ghost' && disabled,
          'text-blue-800 border bg-white border-blue-400 hover:bg-blue-100 active:bg-blue-200':
            variant === 'inverted-primary' && !disabled,
          'text-blue-800 border bg-white border-blue-400 opacity-30 cursor-not-allowed':
            variant === 'inverted-primary' && disabled,
          'text-green-800 border bg-white border-green-500 hover:bg-green-200 active:bg-green-300':
            variant === 'inverted-construct' && !disabled,
          'text-green-800 border bg-white border-green-500 opacity-30 cursor-not-allowed':
            variant === 'inverted-construct' && disabled,
          'text-red-800 border bg-white border-red-500 hover:bg-red-100 active:bg-red-200':
            variant === 'inverted-destruct' && !disabled,
          'text-red-800 border bg-white border-red-500 opacity-30 cursor-not-allowed':
            variant === 'inverted-destruct' && disabled,
        },
        {
          'border-[1.5px]': disabled && variant === 'secondary',
          'pointer-events-none': Component === 'a' && disabled,
          'hover:no-underline cursor-pointer': Component === 'a',
          'rounded-3xl': rounded,
          'text-xs font-medium': variant !== 'link',
          'focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:z-1':
            !disabled,
        },
        'group',
        className
      )}
    >
      {isLoading ? (
        <span className="flex items-center gap-2">
          <Spinner /> {loadingText}
        </span>
      ) : (
        children
      )}
    </Component>
  );
});
