import { ReactNode, useCallback, useMemo, useState } from 'react';

import UpsellModal from '@/components/common/UpsellModal';
import { UpsellProvider } from '@/components/common/UpsellProvider';
import NotificationContextProvider from '@/contexts/NotificationContext';
import { UpsellFeature } from '@/generated/graphql';
import ToastsContextProvider from '@/rollbar-ui/contexts/ToastsContext';

import * as Sidebar from './Sidebar';

type AppShellWithMobileSupportProps = {
  children: ReactNode;
};

export default function AppShellWithMobileSupport({
  children,
}: AppShellWithMobileSupportProps) {
  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const [showFeatureCard, setShowFeatureCard] = useState<ReactNode>();
  const [upsellFeature, setUpsellFeature] = useState<UpsellFeature>();
  const handleClose = useCallback(() => {
    setShowUpsellModal(false);
  }, [setShowUpsellModal]);
  const contextValue = useMemo(() => {
    return {
      setShowModal: setShowUpsellModal,
      showModal: showUpsellModal,
      FeatureCard: showFeatureCard,
      setFeatureCard: setShowFeatureCard,
      handleClose,
      upsellFeature: upsellFeature,
      setUpsellFeature: setUpsellFeature,
    };
  }, [showUpsellModal, showFeatureCard, handleClose, upsellFeature]);
  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Sidebar.Desktop className="hidden md:flex" />

      <div className="flex flex-col w-0 flex-1">
        <main className="flex-1 relative focus:outline-none">
          <ToastsContextProvider>
            <NotificationContextProvider>
              <UpsellProvider value={contextValue}>
                {children}
                {showUpsellModal && (
                  <UpsellModal
                    onClose={handleClose}
                    FeatureCard={showFeatureCard}
                    upsellFeature={upsellFeature}
                  />
                )}
              </UpsellProvider>
            </NotificationContextProvider>
          </ToastsContextProvider>
        </main>
      </div>
    </div>
  );
}
