import classnames from 'classnames';
import { FormEventHandler } from 'react';
import {
  signOut,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';

import { logoutUrl } from '@/lib/urls';
import * as Waiting from '@/rollbar-ui/Waiting';

type Props = {
  logOutText: string;
  className?: string;
  nextUrl?: string;
};

/**
 * Log out button which will log users out of both the old and the new system
 * to get them in a clean state
 */
export function LogOutButton({ logOutText, className, nextUrl }: Props) {
  const session = useSessionContext();
  if (session.loading) {
    return <Waiting.Spinner />;
  }

  const onSubmitHandler: FormEventHandler<HTMLFormElement> = async (e) => {
    e.persist();
    e.preventDefault();

    await signOut();
    // something that may be fixed if we upgrade our react types,
    // but right now we have to directly cast bc TS says this is null
    // when it is not.
    (e.target as HTMLFormElement).submit();
  };

  return (
    <form
      method="POST"
      action={logoutUrl(nextUrl)}
      onSubmit={onSubmitHandler}
      className={classnames('font-inherit', className)}
    >
      <input
        type="submit"
        value={logOutText}
        className="bg-white cursor-pointer"
      />
      {nextUrl && (
        <input
          type="hidden"
          name="next_url"
          value={nextUrl}
          data-testid="next-url-after-logout"
        />
      )}
    </form>
  );
}
