import { configureApiClient, ApiClientOptions } from './client';

export const API_BASE_PATH = '/_/mgmt/v1';

const apiOptions: ApiClientOptions = {
  host: process.env.NEXT_PUBLIC_API_HOST ?? '',
  basePath: API_BASE_PATH,
};

configureApiClient(apiOptions);

export * from './projects';
export * from './settings';
export * from './teamsettings';
export * from './teamMember';
export * from './dataProcessingAddendum';
export * from './gqlClient';
