import {
  Provider as RollbarProvider,
  ErrorBoundary,
  useRollbar,
} from '@rollbar/react';
import { AppProps } from 'next/app';
import { ReactElement, useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
  QueryCache,
  MutationCache,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SuperTokensWrapper } from 'supertokens-auth-react';

import { DefaultErrorPage } from '@/components/compat/DefaultErrorPage';
import AppShell from '@/layouts/AppShell';
import { baseConfig } from '@/lib/rollbarConfig';

import AccountProvider from './AccountProvider';
import { Analytics } from './Analytics';
import LaunchDarkly from './LaunchDarkly';

const getDefaultLayout = () => {
  return AppShell;
};

export default function App(props: AppProps) {
  return (
    <SuperTokensWrapper>
      <RollbarProvider
        config={{
          ...baseConfig,
          accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
        }}
      >
        <ErrorBoundary fallbackUI={DefaultErrorPage}>
          <AccountInfoApp {...props}>
            <WrappedApp {...props} />
          </AccountInfoApp>
        </ErrorBoundary>
      </RollbarProvider>
    </SuperTokensWrapper>
  );
}

function WrappedApp(props: AppProps) {
  const { Component, pageProps } = props;
  /* @ts-ignore */
  const Layout = Component.getLayout /* @ts-ignore */
    ? Component.getLayout()
    : getDefaultLayout();

  return (
    <>
      <div className="font-body">
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </div>
      <Analytics />
    </>
  );
}

function AccountInfoApp(props: AppProps & { children: ReactElement }) {
  const { children, pageProps } = props;
  const rollbar = useRollbar();
  // When a graphql query or mutation fails, log the error to Rollbar
  const onError = (error: any) => {
    let response;
    if (error instanceof Error) {
      // If the error's message includes the serialized graphql response,
      // remove it from the message and pass it as a custom field instead
      const respIndex = error.message.indexOf(': {"response"');
      if (respIndex > 0) {
        response = error.message.slice(respIndex + 2); // remove ': '
        error.message = error.message.slice(0, respIndex);
      }
    }
    rollbar.error(error, response ? { response } : {});
  };
  const queryCache = new QueryCache({ onError });
  const mutationCache = new MutationCache({ onError });
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache,
        mutationCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // Avoid memory pressure on server, on client use normal default
            cacheTime: typeof window === 'undefined' ? Infinity : 5 * 60 * 1000,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AccountProvider>
          <LaunchDarkly>{children}</LaunchDarkly>
        </AccountProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
