function formatNumber(number: number) {
  return new Intl.NumberFormat().format(number);
}

function formatDollar(number: number, decimalLength: number = 2) {
  return '$' + number.toFixed(decimalLength);
}

function formatPercent(number: number, decimalLength: number = 2) {
  return (number * 100).toFixed(decimalLength) + '%';
}

function titleCase(text: string) {
  // This naively capitalizes the first letter of each word,
  // not taking special cases into account (it will capitalize
  // words like 'of', 'a', etc). It's best suited for strings that
  // don't contain those special words. If/when we need more sophisticated
  // title casing support, we should use a 3rd-party library.
  if (!text.length) return text;
  const words = text.split(' ');
  const transformedWords = words.map((word) =>
    word.length ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word
  );
  return transformedWords.join(' ');
}

function sentenceCase(text: string) {
  if (!text.length) return text;

  return text[0].toUpperCase() + text.slice(1);
}

export { formatNumber, formatDollar, formatPercent, titleCase, sentenceCase };

/**
 * Takes a list of strings and combines them into a
 * human-readable list separated by commas, with
 * the word 'and' before the last element.
 * Examples:
 * ['A'] => 'A'
 * ['A', 'B'] => 'A and B'
 * ['A', 'B', 'C'] => 'A, B, and C'
 * ['A', 'B', 'C', 'D'] => 'A, B, C, and D'
 */
export function formatList(words: string[]) {
  if (!words.length) return '';
  if (words.length === 1) return words[0];
  let firstWords = [...words];
  const lastWord = firstWords.pop();
  const oxfordComma = firstWords.length > 1 ? ',' : '';
  return firstWords.join(', ') + oxfordComma + ' and ' + lastWord;
}
