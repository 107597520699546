import { SuperTokensConfig } from 'supertokens-auth-react/lib/build/types';
import EmailPasswordWebJs from 'supertokens-auth-react/recipe/emailpassword';
import MultiFactorAuth from 'supertokens-auth-react/recipe/multifactorauth';
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy';
import SessionWebJs from 'supertokens-auth-react/recipe/session';
import ThirdParty, {
  Github,
  Google,
} from 'supertokens-auth-react/recipe/thirdparty';
import Totp from 'supertokens-auth-react/recipe/totp';

import { buildShouldDoInterceptionBasedOnUrlFunction } from '../recipeOverrides/frontend/shouldDoInterceptionBasedOnUrlFunction';

import { appInfo } from './appInfo';

export const frontendConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      Multitenancy.init({
        override: {
          functions: (oI) => ({
            ...oI,
            getTenantId: async (input) => {
              const { userContext } = input;
              if (userContext?.tenantId) {
                return userContext.tenantId;
              }
              return oI.getTenantId(input);
            },
          }),
        },
      }),
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [Github.init(), Google.init()],
        },
        override: {
          //@ts-ignore
          functions: (oI) => ({
            ...oI,
            verifyAndGetStateOrThrowError: async (input) => {
              if (input.userContext.tenantId) {
                const {
                  userContext: { tenantId },
                } = input;
                const fiveMinutesFromNow = Math.round(
                  new Date().getTime() / 1000 + 300
                );
                return {
                  // intentionally set to random string,
                  // this is for idp-initiated logins where there wouldn't be state
                  // in the browser to verify
                  stateForAuthProvider: '',
                  thirdPartyId: 'boxy-saml',
                  tenantId,
                  expiresAt: fiveMinutesFromNow,
                  redirectURIOnProviderDashboard: `${process.env.NEXT_PUBLIC_WEBSITE_BASE}/auth/callback/boxy-saml/saml/${tenantId}`,
                  shouldTryLinkingWithSessionUser: false,
                };
              }
              return oI.verifyAndGetStateOrThrowError(input);
            },
          }),
        },
      }),
      EmailPasswordWebJs.init(),
      SessionWebJs.init({
        sessionTokenBackendDomain: process.env.NEXT_PUBLIC_AUTHN_COOKIE_DOMAIN,
        sessionTokenFrontendDomain: process.env.NEXT_PUBLIC_AUTHN_COOKIE_DOMAIN,
        override: {
          functions: (oI) => ({
            ...oI,
            ...buildShouldDoInterceptionBasedOnUrlFunction(oI),
          }),
        },
      }),
      MultiFactorAuth.init(),
      Totp.init(),
    ],
  };
};
