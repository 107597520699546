'use client';

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import SimpleNotification, {
  NotificationVariant,
} from '@/rollbar-ui/Notifications/Simple';

type NotificationShape = {
  content: ReactNode | ReactNode[];
  variant: NotificationVariant;
};

type PropTypes = {
  children: ReactNode | ReactNode[];
};

const NotificationContext = createContext<
  Dispatch<SetStateAction<NotificationShape | undefined>>
>(() => {});

export default function NotificationContextProvider({ children }: PropTypes) {
  const portalNode = useRef<HTMLElement>(null);

  useEffect(() => {
    portalNode.current = document.getElementById('toast-portal-root');
  }, []);

  const [notification, setNotification] = useState<NotificationShape>();

  return (
    <NotificationContext.Provider value={setNotification}>
      {children}
      {notification &&
        portalNode.current &&
        createPortal(
          <SimpleNotification
            variant={notification.variant}
            onClose={() => setNotification(undefined)}
          >
            {notification.content}
          </SimpleNotification>,
          portalNode.current
        )}
    </NotificationContext.Provider>
  );
}

export function useSendNotification(): (c: NotificationShape) => void {
  return useContext(NotificationContext);
}
