import { ReactNode } from 'react';

const DEFAULT_MESSAGE = 'Sorry, the page you requested could not be found.';

export function NotFound({
  message = DEFAULT_MESSAGE,
}: {
  message?: ReactNode;
}) {
  return (
    <div className="flex flex-col items-center px-12 pb-4">
      <img
        alt="Not found"
        src="/images/sample_error/general-error-state.svg"
        className="p-8"
      />
      <p className="font-bold text-xl pb-2">Not found</p>
      <p className="text-center max-w-prose text-base">{message}</p>
    </div>
  );
}
