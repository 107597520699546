'use client';
import { useEffect } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { analytics } from '@/lib/analytics/analytics';

export function AccountAnalytics() {
  const accountInfo = useAccountInfo();

  // Account details
  const accountSlug = accountInfo?.accountSlug;
  const accountId = accountInfo?.accountId;
  const planId = accountInfo?.planId;
  const subscribedPlan = accountInfo?.subscribedPlan;

  useEffect(() => {
    if (accountSlug) {
      analytics.group(accountSlug, {
        accountId,
        accountSlug,
        planId,
        subscribedPlan,
      });
    }
  }, [accountId, accountSlug, planId, subscribedPlan]);

  return null;
}
