import { DashboardCardTemplate } from '@/generated/graphql';
import { Flags } from '@/lib/flags';
import useAccountUrl from '@/lib/useAccountUrl';
import useFlag from '@/lib/useFlag';
import useMoxWebAccountSettingsUrl from '@/lib/useMoxWebAccountSettingsUrl';

const { NEXT_PUBLIC_ROLLBAR_BASE_URL } = process.env;

type projectUrlFunc = (account: string, project: string) => string;

export const projectSettingsUrl: projectUrlFunc = (account, project) =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/${account}/${project}/settings`;

export const projectMembersUrl: projectUrlFunc = (account, project) =>
  `${projectSettingsUrl(account, project)}/collaborators`;

export const projectIntegrationUrl = (
  account: string,
  project: string,
  integration: string
) =>
  `${projectSettingsUrl(
    account,
    project
  )}/notifications/${integration.toLocaleLowerCase()}/`;

export const useTeamSettingsLink = () => {
  const useOlympusTeamSettings = useFlag(Flags.OLYMPUS_TEAMS_SETTINGS);
  const moxTeamSettingsLink = useMoxWebAccountSettingsUrl('teams');
  const olympusTeamSettingsLink = useAccountUrl('/settings/teams');
  return useOlympusTeamSettings ? olympusTeamSettingsLink : moxTeamSettingsLink;
};

export const useComparePlansUrl = () => {
  return useAccountUrl('/settings/subscriptions/change');
};

export const useSingleDashboardCardUrl = (template: DashboardCardTemplate) => {
  return useAccountUrl(`/dashboard/card/${template.toLowerCase()}`);
};

export function useAccountAccessTokensUrl() {
  const moxAccountAccessTokensUrl =
    useMoxWebAccountSettingsUrl('access_tokens');
  return moxAccountAccessTokensUrl;
}
