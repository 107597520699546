'use client';
import { useEffect } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { analytics } from '@/lib/analytics/analytics';

export function UserAnalytics() {
  const accountInfo = useAccountInfo();

  // User details
  const name = accountInfo?.user.name;
  const userId = accountInfo?.user.id.toString();
  const userEmail = accountInfo?.user.email;
  const createdAt = accountInfo?.user.dateCreated;

  // Account details
  const accountSlug = accountInfo?.accountSlug;
  const accountId = accountInfo?.accountId;
  const planId = accountInfo?.planId;
  const subscribedPlan = accountInfo?.subscribedPlan;

  useEffect(() => {
    if (userId) {
      analytics.identify(userId, {
        name,
        userId,
        userEmail,
        email: userEmail,
        createdAt,

        // TODO: verify with @ZD that these can be removed
        accountSlug,
        accountId,
        planId,
        subscribedPlan,

        groupId: accountId,
      });
    }
  }, [
    accountId,
    accountSlug,
    createdAt,
    name,
    planId,
    subscribedPlan,
    userEmail,
    userId,
  ]);

  return null;
}
