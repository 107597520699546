import classNames from 'classnames';
import Script from 'next/script';
import { useContext } from 'react';

import { DesktopSidebarContext } from './Desktop';
import { useSecondaryNavItems } from './helpers';
import HelpMenu from './HelpMenu';
import NavLink from './NavLink';

export default function SecondaryNav() {
  const { isCollapsed } = useContext(DesktopSidebarContext);

  return (
    <>
      <Script
        id="changeLog"
        dangerouslySetInnerHTML={{
          __html: `
          HW_config = {
            selector: '.HW_updates',
            account: 'vJPbQx',
            translations: {
              title: 'Rollbar Changelog',
              readMore: 'Read more',
            },
          }
        `,
        }}
      />
      <Script strategy="lazyOnload" src="//cdn.headwayapp.co/widget.js" />
      {useSecondaryNavItems().map(({ icon: Icon, id, href, name }) => {
        const className = classNames({
          HW_updates: id === 'nav-changelog',
          'is-collapsed': isCollapsed,
        });

        return (
          <NavLink
            className={className}
            href={href}
            icon={Icon}
            id={id}
            key={id}
            name={name}
          />
        );
      })}
      <HelpMenu />
      <style global={true} jsx={true}>{`
        #HW_badge_cont {
          z-index: 9 !important;
        }
      `}</style>
    </>
  );
}
