import {
  Dialog,
  DialogBackdrop,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';

export type SlideOverProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
  hasCloseButton?: boolean;
  maxWidth?: string;
  innerOverflow?: string;
  hasTopPadding?: boolean;
  shouldOpenFromLeft?: boolean;
  isStickyLeft?: boolean;
};

export default function SlideOver({
  isOpen = false,
  hasCloseButton = true,
  innerOverflow = 'overflow-y-auto',
  hasTopPadding = true,
  shouldOpenFromLeft = false,
  isStickyLeft = false,
  setIsOpen,
  children,
  maxWidth,
}: SlideOverProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static={true}
        className="fixed inset-0 overflow-hidden z-modal"
        open={isOpen}
        onClose={setIsOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <TransitionChild
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogBackdrop className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div
            className={classNames(
              'fixed inset-y-0 max-w-full flex',
              isStickyLeft ? 'left-0' : 'right-0',
              {
                'pt-10': hasTopPadding,
              }
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom={
                shouldOpenFromLeft ? '-translate-x-full' : 'translate-x-full'
              }
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo={
                shouldOpenFromLeft ? '-translate-x-full' : 'translate-x-full'
              }
            >
              <div className={classNames('w-screen max-w-screen', maxWidth)}>
                <div
                  className={classNames(
                    'h-full bg-white shadow-xl',
                    innerOverflow
                  )}
                >
                  {hasCloseButton && (
                    <div className="absolute top-20 right-6 md:right-20 mr-3 h-7 z-20">
                      <button
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                  <section className="w-full h-full">{children}</section>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
