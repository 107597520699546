import { Menu as HeadlessUIMenuItem } from '@headlessui/react';
import classNames from 'classnames';

import Avatar from '@/rollbar-ui/Avatar';

type MenuItemProps = {
  children?: any;
  href?: string;
  active?: false;
  hasNoLeftPadding?: boolean;
  subtitle?: string;
  email?: any;
  icon?: string;
  className?: string;
};

export default function MenuItem({
  children,
  href,
  active,
  hasNoLeftPadding = false,
  email,
  icon,
  subtitle,
  className,
}: MenuItemProps) {
  const Component = href ? 'a' : 'div';
  return (
    <HeadlessUIMenuItem>
      <div
        className={classNames(
          email || icon
            ? 'pt-3 pr-4 pb-1.5 pl-4 flex flex-row items-center no-underline text-black cursor-pointer border-b-0 hover:bg-gray-200'
            : 'flex flex-row w-full pl-2 hover:text-blue-600',
          className
        )}
      >
        {email && <Avatar email={email} size="xl" />}
        {icon && (
          <div className="inline-block rounded-full overflow-hidden bg-gray-100 h-10 w-10 flex justify-center items-center capitalize font-bold">
            {children.charAt(0)}
          </div>
        )}
        <div className="flex flex-col" aria-hidden="true">
          <Component
            href={href}
            className={classNames(
              'w-full p-4 flex flex-row no-underline text-black border-b-0 border-gray-300 owerflow-hidden cursor-pointer relative',
              active ? 'font-bold bg-white' : '',
              'border-b-0 pt-1 pb-1 text-sm',
              {
                'pl-16': !hasNoLeftPadding,
              }
            )}
          >
            <div className="w-full whitespace-nowrap text-ellipsis block overflow-hidden">
              {children}
            </div>
          </Component>
          {subtitle && (
            <div className="text-xs font-medium text-gray-400 pl-4">
              {subtitle}
            </div>
          )}
        </div>
      </div>
    </HeadlessUIMenuItem>
  );
}
