import axios, { AxiosInstance } from 'axios';
import Router from 'next/router';
import qs from 'qs';

import { LOGIN_PATH } from '@/lib/constants';

import { camelCaseResponseInterceptor } from './camelCaseInterceptors';
import { pathParamInterceptor } from './pathParamInterceptor';
import { snakeCaseRequestInterceptor } from './snakeCaseInterceptors';

const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;

export interface ApiClientOptions {
  host: string;
  basePath?: string;
}

function buildUrl(host: string, path: string): string {
  return `${host}${path ?? ''}`;
}

const paramsSerializer = (params: any): string =>
  qs.stringify(params, { arrayFormat: 'repeat' });

let defaultClient: AxiosInstance;

export function configureApiClient(options: ApiClientOptions): AxiosInstance {
  const instance = axios.create({
    baseURL: buildUrl(options.host, options.basePath ?? ''),
    withCredentials: true,
    responseType: 'json', // explicitly setting to default for documentation purposes
    paramsSerializer,
  });
  // intercepters are executed in reverse order of being added
  instance.interceptors.request.use(snakeCaseRequestInterceptor);
  // must be 2nd so that path params are replaced before converting to snake_case
  instance.interceptors.request.use(pathParamInterceptor);

  instance.interceptors.response.use(camelCaseResponseInterceptor, (error) => {
    if (error.response.status === 401) {
      Router.push(`${baseUrl}/${LOGIN_PATH}?next_url=${window.location.href}`);
    }
    return Promise.reject(error);
  });

  defaultClient = instance;
  return apiClient();
}

export function apiClient(): AxiosInstance {
  if (defaultClient === null) {
    throw new Error('failing fast: must configure api client before using it');
  }
  return defaultClient;
}
