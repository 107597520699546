import { LDProvider } from 'launchdarkly-react-client-sdk';
import { ReactElement } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { getClientConfig } from '@/lib/launchDarklyConfig';

type LaunchDarklyProps = {
  children: ReactElement;
};

export default function LaunchDarkly({ children }: LaunchDarklyProps) {
  const accountInfo = useAccountInfo();

  if (!accountInfo) {
    throw new Error(
      'Account info must be loaded before rendering LaunchDarkly'
    );
  }

  const clientSideID = process.env.NEXT_PUBLIC_LD_KEY ?? '';
  const clientConfig = getClientConfig(accountInfo);

  const { hash, ...context } = clientConfig;

  const options = {
    // hash is required for secure_mode to work in production.
    // See https://docs.launchdarkly.com/sdk/client-side/javascript#secure-mode
    hash,
  };

  return (
    <LDProvider clientSideID={clientSideID} context={context} options={options}>
      {children}
    </LDProvider>
  );
}
