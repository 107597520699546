import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import Button from '@/rollbar-ui/Button/Button';

type Props = {
  actionButtonText?: string;
  cancelButtonText?: string;
  footer?: ReactNode;
  hasCloseButton?: boolean;
  onAction?: () => void;
  onCancel?: () => void;
  testId?: string;
  title?: string;
  variant?: any;
  actionButtonDisabled?: boolean;
};

const ModalFooter: FC<Props> = (props) => {
  const {
    actionButtonText = 'OK',
    cancelButtonText = 'Cancel',
    footer,
    onAction,
    onCancel,
    variant = 'primary',
  } = props;

  return (
    <div
      className={classNames(
        'flex flex-col lg:flex-row pt-6 pb-2 lg:px-2',
        footer ? 'justify-between' : 'justify-end'
      )}
    >
      {footer && <div className="items-start mb-4 lg:mb-0 pt-1">{footer}</div>}

      <span className="flex flex-col lg:flex-row">
        {onCancel && (
          <span className="hidden lg:flex">
            <Button size="lg" variant="ghost" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          </span>
        )}

        {onAction && (
          <Button
            size="lg"
            variant={variant}
            className="lg:ml-4"
            onClick={onAction}
            disabled={props.actionButtonDisabled}
          >
            {actionButtonText}
          </Button>
        )}

        {onCancel && (
          <span className="lg:hidden pt-2">
            <Button size="lg" variant="ghost" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          </span>
        )}
      </span>
    </div>
  );
};

export { ModalFooter };
