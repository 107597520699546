import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import { MouseEventHandler, ReactNode } from 'react';

type ExternalLinkProps = Pick<LinkProps, 'href'> & {
  children: ReactNode;
  className?: string;
  mode?: 'dark' | 'light';
  onClick?: MouseEventHandler;
  title?: string;
};

export function ExternalLink({
  children,
  className,
  href,
  mode = 'light',
  onClick,
  title,
  ...props
}: ExternalLinkProps) {
  return (
    <Link
      {...props}
      className={classNames('hover:underline', className, {
        'text-blue-700 hover:text-blue-500': mode === 'light',
        'text-blue-300 hover:text-blue-400': mode === 'dark',
      })}
      href={href}
      onClick={onClick}
      rel="noreferrer"
      target="_blank"
      title={title}
    >
      {children}
      {' ↗'}
    </Link>
  );
}
