import classnames from 'classnames';
import { useContext } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';

import { DesktopSidebarContext } from './Desktop';

type Props = {
  onClick?: () => void;
};

export default function SidebarHeader({ onClick }: Props) {
  const { accountSlug } = useAccountInfo();
  const { isCollapsed } = useContext(DesktopSidebarContext);

  return (
    <button
      className="h-full w-full p-3 cursor-default xl:cursor-pointer"
      onClick={onClick}
    >
      <header
        className={classnames(
          'h-full flex items-center border-bottom border-solid border-gray-100',
          isCollapsed
            ? 'flex-col justify-center'
            : 'flex-col justify-center xl:flex-row xl:justify-start'
        )}
      >
        <img
          className="h-6 w-6"
          src="/images/common/rollbar-icon-white.svg"
          alt=""
          aria-hidden="true"
        />
        <span
          className={classnames(
            'text-sidebar-nav leading-4 tracking-sidebar-nav text-white text-left min-w-0',
            isCollapsed
              ? 'sr-only'
              : 'sr-only xl:not-sr-only xl:ml-3 truncate !overflow-hidden block'
          )}
        >
          {accountSlug}
        </span>
      </header>
    </button>
  );
}
