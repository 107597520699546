import SessionWebJs from 'supertokens-auth-react/recipe/session';

const frontendApiHostnames = [
  process.env.NEXT_PUBLIC_WEBSITE_BASE,
  process.env.NEXT_PUBLIC_API_HOST,
  process.env.NEXT_PUBLIC_GQL_ENDPOINT,
  // this will fail the build if these environment variables are not present,
  // which they should always be or our app wouldn't work
].map((url) => new URL(url!).hostname);

export function buildShouldDoInterceptionBasedOnUrlFunction(
  oI: SessionWebJs.RecipeInterface
): Pick<SessionWebJs.RecipeInterface, 'shouldDoInterceptionBasedOnUrl'> {
  return {
    shouldDoInterceptionBasedOnUrl: (
      url,
      apiDomain,
      sessionTokenBackendDomain
    ) => {
      try {
        let urlObj = new URL(url);
        return frontendApiHostnames.includes(urlObj.hostname);
      } catch (ignored) {}

      return oI.shouldDoInterceptionBasedOnUrl(
        url,
        apiDomain,
        sessionTokenBackendDomain
      );
    },
  };
}
